<template>
  <div id="admin-dashboard" class="student-dashboard">
    <annoucementsDashbordComponent></annoucementsDashbordComponent>
    <v-row class="padding-top">
      <v-col cols="12" sm="12">
        <h2 class="main-color dashboard-title">{{ $t("Dashboard") }}</h2>
      </v-col>

      <v-col cols="12" md="12">
        <div class="cart-sec exams-table">
          <!-- all exam results for student -->
          <DataTableComponent
            :table="table"
            v-if="studentExamResult"
          ></DataTableComponent>
          <!-- <v-alert v-if="studentExamResult.length == 0" class="info_alert">
            No exam results are added
          </v-alert> -->
        </div>
      </v-col>
    </v-row>
    <!-- Assignments Table -->
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :options.sync="options"
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="items"
          item-key="id"
          class="elevation-1 level1 templates"
          text="left"
          hide-default-footer
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col md="4" sm="12">
                <h3 class="custom-header main-color">
                  {{ $t("Assignments") }}
                </h3>
              </v-col>
              <v-col md="4" sm="12">
                <label class="search" for="inpt_search">
                  <input
                    id="inpt_search"
                    class="input_search"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    type="text"
                    @keyup.enter="getAllData(page)"
                    v-model.trim="search"
                    :title="$t('Search')"
                  />

                  <v-icon
                    @click="getAllData(page)"
                    class="search-icon"
                    :title="$t('Search')"
                    >search</v-icon
                  >
                </label>

                <span title="Filter">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>
                    <!-- <v-list-item class="select" style="">
                      <v-autocomplete
                        v-model="filter.states"
                        label="State"
                        :items="states"
                        @change="hideLabel = true"
                        item-text="name"
                        item-value="id"
                        solo
                      ></v-autocomplete>
                    </v-list-item> -->
                    <v-list-item class="select" style="">
                    <v-autocomplete :label="$t('Quarters')" :items="allQuarters" item-text="name" item-value="id" v-model="filter.quarters"
                      solo></v-autocomplete>
                    </v-list-item>
                    <v-list-item class="select">
                      <v-autocomplete
                        v-model="filter.teachers"
                        :label="$t('Teacher')"
                        :items="teachers"
                        @change="hideLabel = true"
                        item-text="name"
                        item-value="id"
                        multiple
                        solo
                      ></v-autocomplete>
                    </v-list-item>
                    <v-list-item class="select">
                      <v-autocomplete
                        v-model="filter.subjects"
                        :label="$t('Subjects')"
                        :items="subjects"
                        @change="hideLabel = true"
                        item-text="name"
                        item-value="id"
                        multiple
                        solo
                      ></v-autocomplete>
                    </v-list-item>
                    <v-list>
                      <v-card-actions> </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
              </v-col>

              <v-col md="3" sm="12" class="text-right">
                <!-- ==================================================filters================================================== -->
                <p></p>
                <router-link
                  class="main-color"
                  target="_blank"
                  to="/homework?page=1"
                  ><b>{{ $t("All Assignments") }}</b></router-link
                >
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              icon
              :to="'/StudentHomeWorkView/' + item.id"
              v-if="!item.is_submited"
              :title="$t('Answer Assignment')"
            >
              <v-icon class="main-color">remove_red_eye</v-icon></v-btn
            >
            <v-btn
              icon
              :to="'/StudentHomeWorkDetails/' + item.id"
              v-if="item.is_submited == true"
              :title="$t('View Assignment Details')"
            >
              <v-icon class="main-color">question_answer</v-icon></v-btn
            >
          </template>
        </v-data-table>
        <!-- ======================================== Pagination ======================================  -->
        <div class="text-center pt-2">
          <v-pagination
            v-if="length > 0 && totalItems > 0"
            v-model="assignment_page"
            :length="length"
            :circle="circle"
            :page="assignment_page"
            :total-visible="totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <!-- end Assignments Table -->

    <v-row>
      <v-col cols="12" sm="12">
        <div class="cart-sec exams-table">
          <v-row>
            <v-col cols="6">
              <h3 class="title">{{ $t("My Weekly Plan") }}</h3>
            </v-col>
            <v-col cols="6" class="row justify-content-end align-self-center">
              <v-btn
                  big
                  class="main-color"
                  @click="printPdf"
                  v-if="isConfirmed"
                  :title="$t('Print PDF')"
                  :class="currentAppLocale == 'ar' ? 'text-left' : 'text-right'"
                  style="background-color: transparent; box-shadow: none !important"
              >
                <v-icon large class="main-color">print</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row style="overflow: auto">

            <ScheduleComponent
              :editable="false"
              :classId="classId"
              :academicId="academicId"
              :vacations="true"
              :HiddenNoData="true"
              v-if="classId && academicId"
              @changeSelectedStudentChildId="selectedAcademicWeek = $event"
              @changeIsConfirmed="isConfirmed = $event"
            ></ScheduleComponent>
            <!-- <WeeklyPlanComponent
              :editable="false"
              :classId="classId"
              :academicId="academicId"
              :teacherId="null"
            ></WeeklyPlanComponent> -->
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="6">
            <div class="cart-sec" id="calendar">
              <v-row>
                <v-col cols="6" md="6">
                  <h3 class="calendar-title">{{ $t("Event Calendar") }}</h3>
                </v-col>
                <v-col cols="6" md="6">
                  <!-- <div class="text-right">
                    <v-icon class="mr-2" big> more_horiz </v-icon>
                  </div> -->
                  <v-menu bottom right>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        outlined
                        color="grey darken-2"
                        class="calendar-date-select"
                        v-on="on"
                      >
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="type = 'day'">
                        <v-list-item-title>{{ $t("Day") }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'week'">
                        <v-list-item-title>{{ $t("Week") }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'month'">
                        <v-list-item-title>{{ $t("Month") }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = '4day'">
                        <v-list-item-title>{{
                          $t("4 days")
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-center">
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small>mdi-chevron-left</v-icon>
                  </v-btn>
                  <div class="main-color canlendar-date">{{ title }}</div>
                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <!-- <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                  >
                    Today
                  </v-btn>
                 
                  <v-spacer></v-spacer> -->

              <v-sheet height="350px">
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  :events="events"
                  :event-color="getEventColor"
                  :now="today"
                  :type="type"
                  :event-more="true"
                  @click:event="showEvent"
                  @click:more="viewDay"
                  @click:date="viewDay"
                  @change="updateRange"
                ></v-calendar>
                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  offset-x
                >
                  <v-card color="grey lighten-4" min-width="350px" flat>
                    <v-toolbar :color="selectedEvent.color" dark>
                      <!-- <v-btn icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn> -->
                      <v-toolbar-title
                        v-html="selectedEvent.name"
                      ></v-toolbar-title>
                      <v-spacer></v-spacer>
                      <!-- <v-btn icon>
                        <v-icon>mdi-heart</v-icon>
                      </v-btn> -->
                      <!-- <v-btn icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn> -->
                    </v-toolbar>
                    <!-- <v-card-text>
                      <span v-html="selectedEvent.details"></span>
                    </v-card-text> -->
                    <!-- <v-card-actions>
                      <v-btn
                        text
                        color="secondary"
                        @click="selectedOpen = false"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions> -->
                  </v-card>
                </v-menu>
              </v-sheet>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="cart-sec pia-chart">
              <v-row>
                <v-col cols="12" sm="4">
                  <h3 class="main-color chart-header">
                    {{ $t("Attendance") }}
                  </h3>
                </v-col>
                <v-col
                  cols="8"
                  sm="8"
                  style="padding-bottom: 0; padding-top: 0"
                >
                  <!-- <div class="text-right">
                    <v-icon class="mr-2" big> more_horiz </v-icon>
                  </div> -->
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="academicWeeks"
                        v-model="selectedAcademicWeek"
                        item-text="name"
                        item-value="id"
                        @change="getAttendanceStatistics"
                        dense
                        solo
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }} ({{ item.from }} - {{ item.to }})
                        </template>
                        <template v-slot:selection="{ item }">
                          {{ item.name }} ({{ item.from }} - {{ item.to }})
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6">
                  <canvas id="student-doughnut-chart" height="221px"></canvas
                ></v-col>
                <v-col cols="12" sm="6">
                  <ul class="list-unstyled chartValues">
                    <li>
                      <span class="val">
                        <v-icon class="dashboard-blue" small
                          >trip_origin</v-icon
                        >
                        {{ $t("Present") }}
                      </span>
                      <span class="num">{{ attendance_count }}</span>
                    </li>
                    <li>
                      <span class="val">
                        <v-icon class="dashboard-purple" small
                          >trip_origin</v-icon
                        >
                        {{ $t("Absent") }}
                      </span>
                      <span class="num">{{
                        all_attendance - attendance_count
                      }}</span>
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row> </v-row>
      </v-col>

      <!-- <v-col cols="12" md="2" class="notification-section">
        <div class="date-sec">
          <p class="text-center dayName">
            {{ noteDate.day }}
            <span
              class="dayNote"
              v-if="
                day_notes &&
                not_in_active_year == 0 &&
                confirmed == 1 &&
                vacation == 0
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="dayNoteIcon">{{
                    "speaker_notes"
                  }}</v-icon>
                </template>
                <span>{{ day_notes }}</span>
              </v-tooltip>
            </span>
          </p>
          <span class="prev">
            <v-icon large @click="nextPrevDay(noteDate.date, false)"
              >mdi-chevron-left</v-icon
            >
          </span>
          <v-text-field
            id="note-date"
            class="dayNotes"
            v-model.trim="noteDate.date"
            autocomplete="off"
            solo
            dense
          ></v-text-field>
          <span class="next">
            <v-icon large @click="nextPrevDay(noteDate.date, true)"
              >mdi-chevron-right</v-icon
            >
          </span>
        </div>

        <v-alert
          color="#eaeefc"
          v-if="
            vacation == 1 ||
            confirmed == 0 ||
            not_in_active_year == 1 ||
            lessons_notes.length == 0
          "
        >
          {{ $t("no weekly plan added for this day") }}
        </v-alert>

        <div
          v-if="
            not_in_active_year == 0 &&
            confirmed == 1 &&
            lessons_notes.length > 0 &&
            vacation == 0
          "
        >
          <div
            class="notification"
            v-for="(note, index) in lessons_notes"
            :key="index"
          >
            <p class="notification-note main-color">{{ note.subject }}</p>
            <v-alert
              color="#eaeefc"
              v-if="
                !note.notes && !note.class_work_notes && !note.home_work_notes
              "
            >
              {{ $t("no weekly plan added for this lesson") }}
            </v-alert>
            <h4 id="note" v-if="note.notes">{{ $t("Notes") }}</h4>
            <p class="title" v-if="note.notes" style="white-space: normal">
              {{ note.notes }}
            </p>
            <h4 id="classwork" v-if="note.class_work_notes">
              {{ $t("Classwork") }}
            </h4>
            <p class="title" v-if="note.class_work_notes">
              {{ note.class_work_notes }}
            </p>
            <h4 id="Homework" v-if="note.home_work_notes">
              {{ $t("Homework") }}
            </h4>
            <p class="title" v-if="note.home_work_notes">
              {{ note.home_work_notes }}
            </p>
          </div>
        </div>
      </v-col> -->

      <!-- <div class="date-sec date-secScroll">
        <p class="text-center dayName">
          {{ noteDate.day }}
          <span
            class="dayNote"
            v-if="
              day_notes &&
              not_in_active_year == 0 &&
              confirmed == 1 &&
              vacation == 0
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="dayNoteIcon">{{
                  "speaker_notes"
                }}</v-icon>
              </template>
              <span>{{ day_notes }}</span>
            </v-tooltip>
          </span>
        </p>
        <span class="prev">
          <v-icon large @click="nextPrevDay(noteDate.date, false)"
            >mdi-chevron-left</v-icon
          >
        </span>
        <v-text-field
          id="note-date2"
          class="dayNotes"
          v-model.trim="noteDate.date"
          autocomplete="off"
          solo
          dense
          @keydown.prevent
        ></v-text-field>
        <span class="next">
          <v-icon large @click="nextPrevDay(noteDate.date, true)"
            >mdi-chevron-right</v-icon
          >
        </span>
      </div> -->
      <div class="notificationScroll">
        <v-alert
          color="#eaeefc"
          v-if="
            vacation == 1 ||
            confirmed == 0 ||
            not_in_active_year == 1 ||
            lessons_notes.length == 0
          "
        >
          {{ $t("no weekly plan added for this day") }}
        </v-alert>
        <!-- <v-alert color="#eaeefc" v-if="not_in_active_year == 1">
          no weekly plan added for this day
        </v-alert>
        <v-alert color="#eaeefc" v-if="confirmed == 0">
          no weekly plan added for this day
        </v-alert> -->

        <div
          v-if="
            not_in_active_year == 0 &&
            confirmed == 1 &&
            lessons_notes.length > 0 &&
            vacation == 0
          "
        >
          <div
            class="notifyItem"
            v-for="(note, index) in lessons_notes"
            :key="index"
          >
            <div class="notification">
              <p class="notification-note main-color">{{ note.subject }}</p>
              <v-alert
                color="#eaeefc"
                v-if="
                  !note.notes && !note.class_work_notes && !note.home_work_notes
                "
              >
                {{ $t("no weekly plan added for this lesson") }}
              </v-alert>
              <h4 id="note" v-if="note.notes">{{ $t("Notes") }}</h4>
              <p class="title" v-if="note.notes" style="white-space: normal">
                {{ note.notes }}
              </p>
              <h4 id="classwork" v-if="note.class_work_notes">
                {{ $t("Classwork") }}
              </h4>
              <p class="title" v-if="note.class_work_notes">
                {{ note.class_work_notes }}
              </p>
              <h4 id="Homework" v-if="note.home_work_notes">
                {{ $t("Homework") }}
              </h4>
              <p class="title" v-if="note.home_work_notes">
                {{ note.home_work_notes }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- socialmedia Component -->
      <SocialMedia :socialChannels="socialChannels" />
    </v-row>
  </div>
</template>

<script>
import Chart from "chart.js";
import axios from "axios";
// import ACL from "../../acl";
import annoucementsDashbordComponent from "../../components/annoucementsDashbordComponent";
import { DashboardMixin } from "../../mixins/DashboardMixin";
import ScheduleComponent from "../../components/Schedule/ScheduleComponent";
// import WeeklyPlanComponent from "../../components/Schedule/WeeklyPlanComponent";
import DataTableComponent from "../../components/DataTableComponent";
import SocialMedia from "../../components/socialmediaComponent/socialmedia";

export default {
  mixins: [DashboardMixin],
  name: "StudentDashboard",
  components: {
    ScheduleComponent,
    // WeeklyPlanComponent,
    DataTableComponent,
    annoucementsDashbordComponent,
    SocialMedia,
  },
  data() {
    return {
      filterMenu: false,
      studentExamResult: [],
      studentInfo: [],
      noteDate: {
        date: "",
        day: "",
      },
      allQuarters:[],
      day_notes: "",
      vacation: 0,
      not_in_active_year: 0,
      confirmed: 0,
      lessons_notes: [],
      assignment_page: 1,
      table: {
        modal: "All Exam Results",
        headers: [
          {
            text: this.$i18n.t("Exam Name"),
            value: "exam_name", // not sortable item
            sortable: false,
          },
          {
            text: this.$i18n.t("Subject"),
            value: "subject_name", // not sortable item
            sortable: false,
          },
          {
            text: this.$i18n.t("Qualifier"),
            value: "q_title", // not sortable item
            sortable: false,
          },
          {
            text: this.$i18n.t("Mark"),
            value: "student_mark/exam_mark",
            sortable: false,
          },
          {
            text: this.$i18n.t("Exam Date"),
            value: "exam_date", // not sortable item
            sortable: false,
          },
          // {
          //   text: "Academic Period",
          //   value: "academic_year_name", // not sortable item
          //   sortable: false,
          // },
        ],
        title: this.$i18n.t("All Exam Results"),
        editedItem: {},
        apiEndPoints: {
          list: "students/getStudentMarksStudentDashboard",
        },
        permissions: {
          list: "dashboard",
        },
      },
      options: {
        itemsPerPage: 15,
      },
      items: [],
      filter: {
        teachers: [],
        states: "Active",
        subjects: [],
        quarters:"",
      
      },
      search: "",
      teachers: [],
      subjects: [],
      // states: ["", "New", "Active", "Completed"],
      circle: true,
      page: 1,
      length: "",
      totalVisible: 3,
      totalItems: "",
      headers: [
        {
          text: this.$i18n.t("Title"),
          value: "title",
          sortable: false,
        },
        {
          text: this.$i18n.t("Subject"),
          value: "subject",
          sortable: false,
        },

        {
          text: this.$i18n.t("Ddate"),

          value: "due_date",
          sortable: false,
        },
        {
          text: this.$i18n.t("Teacher"),
          value: "teacher",
          sortable: false,
        },
        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],

      nationalities: [this.$i18n.t("saudi"), this.$i18n.t("egypt")],
      years: ["2015", "2016", "2017", "2018", "2019", "2020"],
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      start: null,
      end: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      classId: "",
      academicId: "",
      academicWeeks: [],
      selectedAcademicWeek: "",
      isConfirmed:"",
      attendance_count: "",
      all_attendance: "",
      studentChart: "",
      loading: false,
    };
  },
  computed: {
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
  watch: {
    // "noteDate.date": {
    //   handler() {
    //     this.getDayName();
    //     this.getNotesDataForStudent(this.noteDate.date);
    //   },
    // },
    assignment_page: {
      handler() {
        this.getAllData(this.assignment_page);
      },
      deep: true,
    },
    search: {
      handler() {
        this.page = 1;
        this.getAllData(this.assignment_page);
      },
      deep: true,
    },
    filter: {
      handler() {
        this.page = 1;
        this.getAllData(this.assignment_page);
      },
      deep: true,
    },
  },
  methods: {
    getAllData(assignment_page = null) {
      if (assignment_page == null) {
        assignment_page = this.assignment_page;
      }
      axios
        .get(
          this.getApiUrl +
            "/homework?paginate=5&search=" +
            this.search +
            "&page=" +
            assignment_page,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.data;
          this.loading = false;
          let data = response.data.data.data;
          let teachers = this.teachers;
          let subjects = this.subjects;

          data.forEach((item) => {
            let subjecObj = {
              name: item.subject,
              id: item.subject_id,
            };
            let teaccObj = {
              name: item.teacher,
              id: item.teacher_id,
            };

            subjects.push(subjecObj);
            teachers.push(teaccObj);
          });

          this.teachers = teachers;
          this.subjects = subjects;
        });
    },
    printPdf() {
      axios
        .get(
          this.getApiUrl +
            "/weekly_plans/get/" +
            this.academicId +
            "/" +
            this.classId +
            "/" +
            this.selectedAcademicWeek +
            "?pdf_print=true",
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            let printLink = response.data.data;
            window.open(printLink, "_blank");
          } else {
            this.snack = true;
              this.snackColor = "error";
              this.snackText = response.data.status.message;
              alert(response.data.status.message);

          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
    getAcademicWeeks() {
      axios
        .get(this.getApiUrl + "/schedules/getWeeklyPlanWeeks?dashboard=true", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((res) => {
          if (res.data.status.error == false) {
            this.academicWeeks = res.data.data.weeks;
            this.selectedAcademicWeek = res.data.data.defaultWeekId;
          }
          this.getAttendanceStatistics();
        })
        .catch((err) => {
          console.log(err);
        });
    },
   
    getAttendanceStatistics() {
      var segment = "";
      if (this.selectedAcademicWeek) {
        segment = "/" + this.selectedAcademicWeek;
      }
      axios
        .get(
          this.getApiUrl + "/dashboard/student/attendance_statistics" + segment,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((res) => {
          if (res.data.status.error == false) {
            this.attendance_count = res.data.data.attendance_count;
            this.all_attendance = res.data.data.all_attendance;
            this.createPiaChart();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createPiaChart() {
      if (this.studentChart) {
        this.studentChart.destroy();
      }
      // if ($("#student-doughnut-chart").length) {
      var doughnutChartData = {
        labels: ["Present", "Absent"],
        datasets: [
          {
            backgroundColor: ["#0fe7da", "#a3a0fb"],
            data: [
              this.attendance_count,
              this.all_attendance - this.attendance_count,
            ],
            label: "Total Students",
          },
        ],
      };
      var doughnutChartOptions = {
        responsive: true,
        maintainAspectRatio: false,

        animation: {
          duration: 2000,
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
        },
      };

      var studentCanvas = $("#student-doughnut-chart").get(0).getContext("2d");
      var _this = this;
      // eslint-disable-next-line no-unused-vars
      this.studentChart = new Chart(studentCanvas, {
        type: "doughnut",
        data: doughnutChartData,
        options: doughnutChartOptions,
        plugins: [
          {
            beforeDraw: function (chart) {
              var width = chart.chart.width,
                height = chart.chart.height,
                ctx = chart.chart.ctx;

              ctx.restore();
              var fontSize = (height / 114).toFixed(2);
              ctx.font = fontSize + "em sans-serif";
              ctx.textBaseline = "middle";

              var text = _this.all_attendance,
                textX =
                  this.currentAppLocale == "en" ||
                  this.currentAppLocale == undefined
                    ? Math.round((width - ctx.measureText(text).width) / 2)
                    : Math.round((width - ctx.measureText(text).width) / 1.65),
                textY = height / 2;

              ctx.fillText(text, textX, textY);
              ctx.save();
            },
          },
        ],
      });
      // }
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      axios
        .post(this.getApiUrl + "/school/getVacationsInPeriod", [], {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.events = response.data.data;
          }
        });
      this.start = start;
      this.end = end;
      // this.events = events;
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${
            a.getMonth() + 1
          }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
    getStudentScheduleData() {
      axios
        .get(this.getApiUrl + "/schedules/getStudentClassAndAcademicYear", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((res) => {
          if (res.data.status.error == false) {
            this.classId = res.data.data.class_id;
            this.academicId = res.data.data.academicYearId;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getExamsResults() {
      axios
        .get(this.getApiUrl + "/students/getStudentMarksStudentDashboard", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.studentExamResult = response.data.data.data;
          // console.log("length => " + this.studentExamResult.length);
        });
    },
    getStudentInfo() {
      axios
        .get(this.getApiUrl + "/dashboard/student", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          // this.student_info = [];
          this.studentInfo = response.data.data.student_info;
          this.getAcademicWeeks();
        });
    },
    getQuarters() {
      axios
        .get(
          this.getApiUrl +
          "/exams/filter/getQuartersForFilter",
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.allQuarters = response.data.data.quarters;

          //  this.filter.quarters =[];
          this.filter.quarters = response.data.data.currntQuarterid;

        });
    }
  },
  mounted() {
    // this.createPiaChart();
    this.getQuarters();
    this.getStudentScheduleData();
    this.getAllData(this.assignment_page);
    // this.getTeachers();
    // this.createLineChart();
    // this.$refs.calendar.checkChange();
    // this.getExamsResults();
    this.getStudentInfo();
    // this.getAcademicWeeks();
    // this.getAttendanceStatistics();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_dashboard.scss";

.about {
  padding: 15px 0px 0 !important;
  height: 640px !important;
  .about-title {
    padding: 0 10px;
  }
}

.exams-table {
  // height: 640px !important;
}

.pia-chart {
  height: 500px;

  .table {
    margin-top: 50px;
  }
}

.info_alert {
  // margin-left: 23%;
  margin-top: 20%;
  margin-bottom: 20%;
  color: rgba(0, 0, 0, 0.38);
  text-align: center;
}
</style>
